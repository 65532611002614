import React, { Component } from 'react';
import { Admin, Resource, ListGuesser, fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import  simpleRestProvider from 'ra-data-simple-rest';

// import dataProvider from './../../generals/admin/dataProvider/dataProvider';

// import authProvider from './../../generals/admin/authProvider/authProvider';

import PostList from './../../generals/admin/posts/posts';
import { PostCreate } from './../../generals/admin/posts/create';
import { PostEdit } from './../../generals/admin/posts/edit';

// import ComponentList from './../../generals/admin/components/components';
// import { DataComponentEdit } from './../../generals/admin/components/dataComponents';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = JSON.parse(localStorage.getItem('auth')).success.token;
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

// const MyLoginPage = () => <Login backgroundImage="/img/Back_blog.jpg"/>;

const dataProvider = jsonServerProvider('https://turismoglobal.mx/api', httpClient);

const authProvider = {
  // authentication
  login: ({ username, password }) =>  {
    const request = new Request('https://turismoglobal.mx/api/login', {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(auth => {
            localStorage.setItem('auth', JSON.stringify(auth));
        })
        .catch(() => {
            throw new Error('Network error')
        });
},
  checkError: error => Promise.resolve(),
  checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: params => Promise.resolve(),
};
class AdminMain extends Component {

  componentWillMount() {
    document.body.style.overflow = "auto";
  }

  render() {
    return (
      <div>
        {/* <Admin
          title="TurismoGlobal dashboard"
          authProvider={authProvider}
          dataProvider={simpleRestProvider(global.config.URL.SERVER, httpClient)}
          loginPage={MyLoginPage}>
          <Resource
            name="posts"
            options={{ label: 'Posts' }}
            list={PostList}
            create={PostCreate}
            edit={PostEdit}></Resource>
          <Resource
            name="components"
            options={{ label: 'Componentes' }}
            list={ComponentList}></Resource>
          <Resource
            name="dataComponents"
            options={{ label: '' }}
            edit={DataComponentEdit}></Resource>
        </Admin> */}
        <Admin
          basename="/admin"
          dataProvider={dataProvider}
          authProvider={authProvider}
          >
          <Resource
            name="posts"
            options={{ label: 'Posts' }}
            list={PostList}
            create={PostCreate}
            edit={PostEdit}
            ></Resource>
        </Admin>
      </div>
    );
  }
}

export default AdminMain;