import React, { Component } from 'react';
import './ourServices.scss';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import Earth from './earth';
import Person from './person';
import Light from './light';
import Palm from './palmTree';

class OurServices extends Component {

  render() {
    return (
      <div className="container-our-services">
        <Container maxWidth="xl">
          <div
            className="title">
            Nuestros Servicios
          </div>
          <div className="container-icons">
            <div
              className="container-icon earth"
              >
              <Earth />
              <div className="container-text">
                VIAJES
              </div>
            </div>
            <div
              className="container-icon palm">
              <Palm />
              <div className="container-text">
                BODAS <br/>
                EN PLAYA
              </div>
            </div>
            <div
              className="container-icon light">
              <Light />
              <div className="container-text">
                REPRESENTACIÓN <br/>
                COMERCIAL
              </div>
            </div>
            <div
              className="container-icon person">
              <Person />
              <div className="container-text">
                GRUPOS Y <br/>
                CONVENCIONES
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default OurServices;