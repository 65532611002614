import React, { Component } from 'react';

import {
  Link
} from "react-router-dom";

import './header.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class Header extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div
        className="header-logo"
        data-aos="fade-top"
        data-aos-once="true">
        <Link to="/">
          <img className="logo" src={this.props.logo} alt=""/>
        </Link>
      </div>
    )
  } 
}

export default Header;