import React, { Component } from 'react';
import './ourBenefists.scss';

import Container from '@mui/material/Container';

class OurBenefists extends Component {

  componentDidMount() {
    console.log('aaaaaa');
    window.$(".owl-carousel-comments-benefists").owlCarousel({
      loop: true,
      dots: true,
      autoplay: true,
      autoplayTimeout: 10000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  }


  render() {
    return (
      <div className="container-our-benefists" style={{backgroundImage: 'url("/img/Background_001_TG-7.jpg")'}}>
        <div
          className="container-background"
        >
          {/* <Container maxWidth="md"> */}
            <h2 className="title text-center">
              Obtén Beneficios Exclusivos
            </h2>
            <div className="container-slider">
              <div
                className="container-slider-comments owl-carousel-comments-benefists owl-carousel owl-theme">
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      01
                    </div>
                    <h3 className="sub-title text-center">
                      "Diseño de experiencias maravillosas"
                    </h3>
                    <div className="text text-center">
                      Viajar son sueños y nosotros los hacemos realidad. La experiencia y atención personalizada de nuestros asesores realmente marcan la diferencia. 
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      02
                    </div>
                    <h3 className="sub-title text-center">
                      "Seguridad, respaldo y profesionalismo"
                    </h3>
                    <div className="text text-center">
                      Somos una empresa formada por licenciados en turismo, apasionados por su profesión y el servicio. Más de 9 años en el mercado nos respaldan. 
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      03
                    </div>
                    <h3 className="sub-title text-center">
                      "Si Concierge"
                    </h3>
                    <div className="text text-center">
                      Exclusivo servicio de atención personalizada 24/7. Viajaremos contigo en todo momento y estaremos a tu lado en caso de necesitarnos.
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      04
                    </div>
                    <h3 className="sub-title text-center">
                      "Servicios 100% deducibles"
                    </h3>
                    <div className="text text-center">
                      Somos una empresa legalmente constituida como Sociedad Anónima de Capital Variable, por lo que todas tus contrataciones con deducibles de impuestos.
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      05
                    </div>
                    <h3 className="sub-title text-center">
                      "No te cuesta más"
                    </h3>
                    <div className="text text-center">
                      Por el contrario, contamos con sólidas alianzas con cadenas hoteleras, aerolíneas, inclusive destinos.
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      06
                    </div>
                    <h3 className="sub-title text-center">
                      "Cupones de descuento"
                    </h3>
                    <div className="text text-center">
                      Al ser cliente de TG, te ofrecemos este beneficio para futuras contrataciones, así como certificados de regalo.
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      07
                    </div>
                    <h3 className="sub-title text-center">
                      "Know How"
                    </h3>
                    <div className="text text-center">
                      La experiencia de más de 9 años como empresa en el mercado, pero de más de 20 de nuestro CEO, nos avalan. No pierdas tiempo; ¡lo hacemos por ti!
                    </div>
                  </Container>
                </div>
                <div className="container-item">
                  <Container maxWidth="md">
                    <div className="number">
                      08
                    </div>
                    <h3 className="sub-title text-center">
                      "Sorteos exclusivos"
                    </h3>
                    <div className="text text-center">
                      En agradecimiento a la preferencia que nos brindan, periódicamente realizamos diversas dinámicas y sorteos exclusivos para clientes.
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          {/* </Container> */}
        </div>
      </div>
    );
  }
}

export default OurBenefists;