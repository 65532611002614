import React, { Component } from 'react';
import './header.scss';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

class Header extends Component {

  componentDidMount() {
    window.$(".owl-carousel-comments-header").owlCarousel({
      loop: true,
      dots: false,
      autoplay: true,
      autoplayTimeout: 10000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  }


  render() {
    return (
      <div className="container-header-home">
        <div
            className="container-slider-comments owl-carousel-comments-header owl-carousel  owl-theme">
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/Banner_Compartir.jpg")'
            }}>
            <Container maxWidth="xl">
              <div className="container-text">
                <div
                  className="container-logo-main"
                  >
                    <img className="logo" src="/img/LogoTGv2.svg" alt="" srcSet=""/>
                    <span className="container-phone">
                      <div>
                        <img src="/img/002_icono_teléfono.svg" alt=""/>
                        <span>
                          <a href="tel:3313802891">
                            33138-02891
                          </a>
                        </span>
                      </div>
                    </span>
                </div>
                <div>
                  <div
                    className="section-text-left">
                      <h1>
                        COMPARTE
                      </h1>
                  </div>
                  <div className="mt-4">
                    <div
                      className="text-header-main color-text-dark"
                      >
                        <h2>
                          Apasionados del Turismo
                        </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="text-center container-button"
                  >
                    <a href="http://www.reservatuviaje.mx/default.aspx?agentID=1295">
                      <Button>
                        Reservar
                      </Button>
                    </a>
                </div>
              </div>
            </Container>
          </div>
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/banner_viaja.jpg")'
            }}>
            <Container maxWidth="xl">
              <div className="container-text">
                <div
                  className="container-logo-main"
                  >
                    <img className="logo" src="/img/LogoTGv2.svg" alt="" srcSet=""/>

                    <span className="container-phone">
                      <div>
                        <img src="/img/002_icono_teléfono.svg" alt=""/>
                        <span>
                          <a href="tel:3313802891">
                            33138-02891
                          </a>
                        </span>
                      </div>
                    </span>
                </div>
                <div>
                  <div
                    className="section-text-left">
                      <h1>
                        VIAJA
                      </h1>
                  </div>
                  <div className="mt-4">
                    <div
                      className="text-header-main color-text-dark"
                      >
                        <h2>
                          Apasionados del Turismo
                        </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="text-center container-button"
                  >
                    <a href="http://www.reservatuviaje.mx/default.aspx?agentID=1295">
                      <Button>
                        Reservar
                      </Button>
                    </a>
                </div>
              </div>
            </Container>
          </div>
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/banner_sueña.jpg")'
            }}>
            <Container maxWidth="xl">
              <div className="container-text">
                <div
                  className="container-logo-main"
                  >
                    <img className="logo" src="/img/LogoTGv2.svg" alt="" srcSet=""/>
                    <span className="container-phone">
                      <div>
                        <img src="/img/002_icono_teléfono.svg" alt=""/>
                        <span>
                          <a href="tel:3313802891">
                            33138-02891
                          </a>
                        </span>
                      </div>
                    </span>
                </div>
                <div>
                  <div
                    className="section-text-left">
                      <h1>
                        CONOCE
                      </h1>
                  </div>
                  <div className="mt-4">
                    <div
                      className="text-header-main color-text-dark"
                      >
                        <h2>
                          Apasionados del Turismo
                        </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="text-center container-button"
                  >
                    <a href="http://www.reservatuviaje.mx/default.aspx?agentID=1295">
                      <Button>
                        Reservar
                      </Button>
                    </a>
                </div>
              </div>
            </Container>
          </div>
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/banner_conoce.jpg")'
            }}>
            <Container maxWidth="xl">
              <div className="container-text">
                <div
                  className="container-logo-main"
                  >
                    <img className="logo" src="/img/LogoTGv2.svg" alt="" srcSet=""/>
                    <span className="container-phone">
                      <div>
                        <img src="/img/002_icono_teléfono.svg" alt=""/>
                        <span>
                          <a href="tel:3313802891">
                            33138-02891
                          </a>
                        </span>
                      </div>
                    </span>
                </div>
                <div>
                  <div
                    className="section-text-left">
                      <h1>
                        SUEÑA
                      </h1>
                  </div>
                  <div className="mt-4">
                    <div
                      className="text-header-main color-text-dark"
                      >
                        <h2>
                          Apasionados del Turismo
                        </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="text-center container-button"
                  >
                    <a href="http://www.reservatuviaje.mx/default.aspx?agentID=1295">
                      <Button>
                        Reservar
                      </Button>
                    </a>
                </div>
              </div>
            </Container>
          </div>
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/banner_vive.jpg")'
            }}>
            <Container maxWidth="xl">
              <div className="container-text">
                <div
                  className="container-logo-main"
                  >
                    <img className="logo" src="/img/LogoTGv2.svg" alt="" srcSet=""/>
                    <span className="container-phone">
                      <div>
                        <img src="/img/002_icono_teléfono.svg" alt=""/>
                        <span>
                          <a href="tel:3313802891">
                            33138-02891
                          </a>
                        </span>
                      </div>
                    </span>
                </div>
                <div>
                  <div
                    className="section-text-left">
                      <h1>
                        VIVE
                      </h1>
                  </div>
                  <div className="mt-4">
                    <div
                      className="text-header-main color-text-dark"
                      >
                        <h2>
                          Apasionados del Turismo
                        </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="text-center container-button"
                  >
                    <a href="http://www.reservatuviaje.mx/default.aspx?agentID=1295">
                      <Button>
                        Reservar
                      </Button>
                    </a>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;