import React, { Component } from 'react';
import renderHTML from 'react-render-html';

import './SectionHeaderPost.scss';

import Header from '../../header/header';
import Footer from '../../footer/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@mui/material/Container';

class SectionHeaderPost extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div>
        <div className="section-header-post">
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/Back_blog_Notas.jpg")'
            }}>
            <Container maxWidth="xl">
              <Header />
            </Container>
            <div className="container-text">
              { this.props.post.title && 
                <div
                  className="section-text-center"
                  data-aos="fade-up"
                  data-aos-once="true">
                    <h1>
                      {renderHTML(this.props.post.title ? this.props.post.title : '')}
                    </h1>
                </div>
              }
            </div>
          </div>
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  } 
}

export default SectionHeaderPost;