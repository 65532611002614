import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

import UploadImages from './../uploadImages/uploadFiles';

export const PostCreate = (props) => (
  <div>
    <div className="mb-3">
      <UploadImages />
    </div>
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Nombre" source="name" />
        <TextInput label="URL *" source="url" />
        <TextInput label="Autor *" source="created_by" />
        <TextInput label="Sitio *" source="created_site" />

        <TextInput label="Titulo *" source="title" />
        <TextInput label="Imagen redes sociales *" source="socialNetworkImage" />
        <RichTextInput label="Resumen *" source="summary" />
        <RichTextInput label="Post *" source="text" />

        <BooleanInput label="Publicar" source="show" defaultValue={false} />
      </SimpleForm>
    </Create>
  </div>
);