import React, { Component } from 'react';

import './SectionHeaderStories.scss';

import Header from '../../header/header';
import Footer from '../../footer/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@mui/material/Container';

class SectionHeaderStories extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div>
        <div className="section-header-stories">
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/Back_blog_Notas.jpg")'
            }}>
            <Container maxWidth="xl">
              <Header />
            </Container>
            <div className="container-text">
              <div
                className="section-text-center"
                data-aos="fade-up"
                data-aos-once="true">
                  <h1>
                    Un blog de <br/> Turismo Global
                  </h1>
              </div>
            </div>
          </div>
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  } 
}

export default SectionHeaderStories;