import React, { Component } from 'react';
import './iTravel.scss';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';

class ITravel extends Component {

  render() {
    return (
      <div className="i-travel" style={{ backgroundImage: 'url(/img/001Back_SiV.jpg)' }}>
        <div className="container-columns">
          <div className="container-info first">
            <div className="container-colums">
              <div>
                <img
                  className="icon-text-section"
                  src="/img/logoSiviajo.svg"
                  alt="Vieaje a la torre de pisa"/>
              </div>
              <div>
                <div className="container-list">
                  <ul className="list-section">
                    <li>Hotelería</li>
                    <li>Paquetes vacacionales</li>
                    <li>Viajes corporativos y de negocios</li>
                    <li>Boletos de avión</li>
                    <li>Cruceros</li>
                    <li>Circuitos, Tours y Actividades</li>
                    <li>Renta de autos</li>
                    <li>Entradas a atracciones</li>
                    <li>Experiencias inolvidables</li>
                  </ul>
                </div>
                <div className="container-btns">
                  <a href="/pdf/Infgrfk_SiViajo.pdf" target="_blank">
                    <div className="btn">
                      <img src="/img/001BTN_Info_Siviajo-8.png" alt=""/>
                      <div>
                        + Info
                      </div>
                    </div>
                  </a>
                  <a href="https://www.facebook.com/pg/Turismo-Global-147885691941132/photos/?tab=album&album_id=3454795644583437" target="_blank">
                    <div className="btn">
                      <img src="/img/001BTN_Promo_SiViajo-8.png" alt=""/>
                      <div>
                        Promociones
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="second">
            {/* <img
              className="img-section"
              src="/img/001Back_SiV.jpg"
              alt="Yo si viajo"
              /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ITravel;