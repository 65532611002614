import React from 'react';
import './App.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Admin from './components/pages/admin/Admin';
import NavBar from './components/generals/navBar/NavBar';
import Home from './components/pages/home/Home';
import Stories from './components/pages/stories/Stories';
import Post from './components/pages/post/Post';

import ScrollToTop from './utils/scrollTop/scrollTop';

import config from './config.js';
global.config = config;

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop>
          <Routes>
            <Route path="/admin/*" element={<Admin/>} />
            <Route path="/blog/:post" element={<Post/>} />
            <Route path="/blog" element={
              <React.Fragment>
                <div className="nav-bar">
                  <NavBar />
                </div>
                <Stories />
              </React.Fragment>
            } />
            <Route path="/" element={
              <React.Fragment>
                <div className="nav-bar">
                  <NavBar />
                </div>
                <Home />
              </React.Fragment>
            } />
          </Routes>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
