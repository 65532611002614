import React, { Component } from 'react';
import './footer.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class Footer extends Component {

  state = {
    email: '',
    name: '',
    comment: '',
    checkedMap: false,
    openMessage: false,
    messageAlert: '',
    iconMessage: ''
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleChangeMap () {
    this.setState({
      checkedMap: !this.state.checkedMap
    });
    setTimeout(() => {
      this.footer.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  sendEmail() {
    if (this.state.email !== '' && this.state.comment !== '') {
      var formData = new FormData();
      formData.append('action', 'correo');
      formData.append('email', this.state.email);
      formData.append('name', this.state.name);
      formData.append('message', this.state.comment);

      fetch('./core/sendComments.php', {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            email: '',
            comment: ''
          });
          this.showMessage('Mensaje enviado', <CheckCircleIcon className="mr-2" />);
        } else {
          this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
        }
      })
      .catch(error => {
        console.log(error);
        this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
      });
    }
  }

  showMessage(message, icon) {
    this.setState({
      openMessage: true,
      messageAlert: message,
      iconMessage: icon
    });
  };

  hideMessage() {
    this.setState({
      openMessage: false
    });
  };

  render() {
    return (
      <div className="container-footer">
        <Container maxWidth="lg">
          <div className="container-info-footer">
            <div
              className="left">
              <div className="title-1">
                Emergencias fuera del horario de atención:
              </div>
              <div className="title-2">
                <a href="https://wa.me/523315287967" target="_blank">
                  (33) 1528 - 7967
                </a>
              </div>
              <div className="title-3">
                o envía un correo electrónico
              </div>

              <div className="hours-1">
                Horario de atentión personalizada:
              </div>
              <div className="hours-2">
                10:00 - 18:00 hrs. L-V
              </div>

              <div className="section-address">
                Dirección:
                <div>
                  <a className="link-address link-address-1" href="https://goo.gl/maps/YTFUdxgK19Km63gDA" target="_blank" rel="noopener noreferrer">
                    Av. Vista a la Campiña 585, Loc. 11 Altos <img className="icon-pin" src="/img/004_icon.svg" alt=""/> 
                  </a> 
                  <a className="link-address" href="https://goo.gl/maps/YTFUdxgK19Km63gDA" target="_blank" rel="noopener noreferrer">
                    Col. Cerro del Tesoro
                  </a> 
                </div>
                <div className="container-email">
                  <a href="mailto:reservaciones@turismoglobal.mx">reservaciones@turismoglobal.mx</a>
                </div>
              </div>
              <div className="container-logos-footer">
                <img className="logo-footer" src="/img/LogoTGv2.svg" alt=""/>

                <a href="https://www.facebook.com/Turismo-Global-147885691941132" className="mr-3" target="_blank" rel="noopener noreferrer">
                  <img className="icon-social" src="/img/007_iconfacebook.svg" alt="facebook"/>
                </a>
                <a href="https://www.instagram.com/siaceptoenlaplaya/" target="_blank" rel="noopener noreferrer">
                  <img className="icon-social" src="/img/006_logoinstagram.svg" alt="facebook"/>
                </a>
              </div>
            </div>
            <div
              className="right">
              <form  noValidate autoComplete="off">
                <TextField
                  className="input-form w-100"
                  label="Tu nombre"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange.bind(this)}/>
                <TextField
                  className="input-form w-100"
                  label="Correo electrónico"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}/>
                <TextField
                  className="input-form w-100"
                  label="Mensaje"
                  name="comment"
                  multiline
                  rows="4"
                  value={this.state.comment}
                  onChange={this.handleChange.bind(this)}
                  margin="normal"/>
                
                <div className="text-right container-btn-send">
                  <span className="reserva">¿LISTO PARA RESERVAR?</span>
                  <Button  onClick={this.sendEmail.bind(this)}>
                    Enviar
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="div-footer text-center">
            2020 TG - TURISMO GLOBAL
          </div>
        </Container>
      </div>
    );
  }
}

export default Footer;