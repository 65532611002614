import React, { Component } from 'react';
import './iMeetings.scss';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';

class IMeetings extends Component {

  render() {
    return (
      <div className="i-meetings" style={{ backgroundImage: 'url(/img/004Back_SiME.jpg)' }}>
        <div className="container-columns">
          <div className="first">
            {/* <img
              className="img-section"
              src="/img/imagen_004-100.jpg"
              alt="Yo si viajo"
              /> */}
          </div>
          <div className="container-info second">
            <div className="container-colums">
              <div>
                <img
                  className="icon-text-section"
                  src="/img/logomeetings.svg"
                  alt="Vieaje a la torre de pisa"/>
              </div>
              <div>
                <div className="container-list">
                  <ul className="list-section">
                    <li>Diseño de tu evento</li>
                    <li>Asesoría personalizada 24/7</li>
                    <li>Gestión de la toma de decisiones</li>
                    <li>Oferta hotelera y de centros de convenciones</li>
                    <li>Planeación, organización, operación y logística</li>
                    <li>Transportación</li>
                    <li>DMC’s</li>
                    <li>Eventos especiales</li>
                    <li>Fiestas Temáticas</li>
                    <li>Programa para invitados</li>
                  </ul>
                </div>
                <div className="container-btns">
                  <a href="/pdf/Infgrfk_SiMeetings.pdf" target="_blank">
                    <div className="btn">
                      <img src="/img/005BTN_Info_SIME-8.png" alt=""/>
                      <div>
                        + Info
                      </div>
                    </div>
                  </a>
                  {/* <a href="https://www.facebook.com/pg/Turismo-Global-147885691941132/photos/?tab=album&album_id=3496553187074349" target="_blank">
                    <div className="btn">
                      <img src="/img/005BTN_Promo_SIME-8.png" alt=""/>
                      <div>
                        Promociones
                      </div>
                    </div>
                  </a> */}
                </div>
              </div>
            </div>
            <div>
              <div
                className="container-title-contact">
                <span
                  className="title">
                  ¿Listo para reservar?
                </span>
                <a href="http://www.reservatuviaje.mx/default.aspx?agentID=1295">
                  <Button>
                    Comienza aquí
                  </Button>
                </a>
              </div>
              <div
                className="text-subsection text-gray">
                O contacta a un <b>asesor</b>, quien con gusto atenderá tu llamada
              </div>
              <div
                className="text-llamanos text-gray">
                Llámanos <a href="tel:3313802891"> (33) 13802891</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IMeetings;