import React, { Component } from 'react';

import Container from '@mui/material/Container';

import './Comments.scss';

class Comments extends Component {

  componentDidMount() {
    window.$(".owl-carousel-comments").owlCarousel({
      loop: true,
      dots: true,
      autoplay: true,
      autoplayTimeout: 10000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  }

  render() {
    return (
      <div className="section-comments">
        <Container maxWidth="md">
          <div
            className="text-center title">
            <h2>
              ¿Qué dicen nuestros clientes?
            </h2>
          </div>
          <div
            className="container-slider-comments owl-carousel-comments owl-carousel owl-theme">
            <div className="text-center">
              <div className="text">
                “Es una gran opción para casarse en la playa sin el estrés de organizar todo... Ofrecen varias opciones de hoteles y tú eliges la mejor para tu presupuesto o preferencias. Rapidez en el servicio y excelente atención al cliente”.
              </div>
              <div className="author">
                Karla Silva
              </div>
              <div className="profession">
                Ingeniero en Sistemas
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Atención clara y precisa, ofrecen muchas opciones de acuerdo a tu presupuesto y siempre son muy atentas al apoyarte y al mandar documentos de viaje. El último viaje que planeé con turismo Global fue muy satisfactorio y el hotel estaba súper!”
              </div>
              <div className="author">
                Alejandra Toscano
              </div>
              <div className="profession">
                Ama de Casa
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “La convención de mi empresa fue todo un éxito, gracias a Turismo Global. Ellos se encargaron prácticamente de todo: planeación, organización, logística y operación del evento. Ya estamos planeando la siguiente edición”
              </div>
              <div className="author">
                Gustavo García
              </div>
              <div className="profession">
                Empresario
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Turismo Global atiende todos nuestros viajes corporativos de una manera muy eficaz. Estamos muy contentos de tenerlos como proveedores, pues me apoyan en todo momento y cubren nuestras necesidades como empresa!”.
              </div>
              <div className="author">
                Gabriela Ornelas
              </div>
              <div className="profession">
                Gerente de Operaciones
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Servicio personalizado. Están al pendiente de todo tu viaje, muy profesionales y super amables”.
              </div>
              <div className="author">
                Ana Laura Correa
              </div>
              <div className="profession">
                Ingeniero en Comunicaciones y Electrónica
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Excelente atención, siempre sus recomendaciones y alternativas son las mejores.”
              </div>
              <div className="author">
                Miriam Lara
              </div>
              <div className="profession">
                Administradora de Empresas
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Atención personalizada y excelente servicio. Tuve una de las mejores experiencias en Bacalar con mi familia”.
              </div>
              <div className="author">
                Juan Insunza
              </div>
              <div className="profession">
                Enfermero
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Excelente servicio, todo para disfrutar tus vacaciones al máximo sin ningún contratiempo, ¡gracias Turismo Global!”
              </div>
              <div className="author">
                Teresa González
              </div>
              <div className="profession">
                Representante Médico
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Excelente opción para disfrutar unas merecidas vacaciones... Gracias!!!”
              </div>
              <div className="author">
                Alejandro Múñoz
              </div>
              <div className="profession">
                Diseñador
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Excelente Servicio... Nuestras vacaciones estuvieron divinas, hotel, reservaciones de vuelo, reservaciones de transportes todo de excelente calidad y precio. 100% recomendable el servicio. ¡GRACIAS!”
              </div>
              <div className="author">
                Lizbeth Gómez
              </div>
              <div className="profession">
                Ama de casa
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “Me atendieron muy bien, las chicas súper atentas y el hotel que me recomendaron muy buen servicio, súper recomendable”.
              </div>
              <div className="author">
                Carmen Iñiguez
              </div>
              <div className="profession">
                Emprendedora
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “¡Excelente! los mejores paquetes para tener unas vacaciones de lujo".
              </div>
              <div className="author">
                Thelma Sánchez
              </div>
              <div className="profession">
                CFE
              </div>
            </div>
            <div className="text-center">
              <div className="text">
                “¡Todo perfecto! Excelente servicio, los mejores para organizar tu viaje. Se los recomiendo al 100%”.
              </div>
              <div className="author">
                Patricia González
              </div>
              <div className="profession">
                Recepcionista
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  } 
}

export default Comments;