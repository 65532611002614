import React, { Component } from 'react';
import './iAccept.scss';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';

class iAccept extends Component {

  render() {
    return (
      <div className="i-accept" style={{ backgroundImage: 'url(/img/002Back_SiA.jpg)' }}>
        <div className="container-columns">
          <div className="first">
            {/* <img
              className="img-section"
              src="/img/Imagen_002-100 2.jpg"
              alt="Yo si viajo"
              /> */}
          </div>
          <div className="container-info second">
            <div className="container-colums">
              <div>
                <img
                  className="icon-text-section"
                  src="/img/010_logosiacepto.svg"
                  alt="Vieaje a la torre de pisa"/>
              </div>
              <div>
                <h2
                  className="sub-title">
                  {/* <span className="bold">Si</span> <span>Acepto</span> */}
                </h2>
                <div className="container-list">
                  <ul className="list-section">
                    <li>Diseño de tu evento</li>
                    <li>Los mejores hoteles con precios altamente competitivos</li>
                    <li>Gestión y asesoría de trámites, montaje para ceremonia (civil, simbólica o religiosa)</li>
                    <li>Recepción y brindis; tornaboda, amenidades nupciales</li>
                    <li>Contratación de proveedores: flores, música, fotografía, maquillaje, etc</li>
                    <li>Programa de actividades para invitados</li>
                    <li>Central de reservaciones</li>
                    <li>Planeación y organización de despedida de solter</li>
                    <li>Lunas de miel</li>
                  </ul>
                </div>
                <div className="container-btns">
                  <a href="/pdf/Infgrfk_SiAcepto.pdf" target="_blank">
                    <div className="btn">
                      <img src="/img/002BTN_Info_SiA-8.png" alt=""/>
                      <div>
                        + Info
                      </div>
                    </div>
                  </a>
                  <a href="https://www.facebook.com/pg/Turismo-Global-147885691941132/photos/?tab=album&album_id=3496553187074349" target="_blank">
                    <div className="btn">
                      <img src="/img/002BTN_Promo_SiA-8.png" alt=""/>
                      <div>
                        Promociones
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default iAccept;