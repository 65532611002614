import React, { Component } from 'react';
import './iMarketing.scss';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';

class iMarketing extends Component {

  render() {
    return (
      <div className="i-marketing" style={{ backgroundImage: 'url(/img/003_TG-2_BackSimeetings.jpg)' }}>
        <div className="container-columns">
          <div className="container-info first">
            <div className="container-colums">
              <div>
                <img
                  className="icon-text-section"
                  src="/img/logomarketing.svg"
                  alt="Vieaje a la torre de pisa"/>
              </div>
              <div>
                <h2
                  className="sub-title">
                  {/* <span className="bold">Si</span> <span>Marketing</span> */}
                </h2>
                <div className="container-list">
                  <ul className="list-section">
                    <li>Representación comercial</li>
                    <li>Revenue Management</li>
                    <li>E-commerce Management</li>
                    <li>Gestión de Social Media</li>
                    <li>Publicidad</li>
                    <li>Investigación de Mercados</li>
                    <li>Análisis de producto: marca, precio, estructura, distribución</li>
                    <li>Benchmarking</li>
                    <li>Marketing & Action Plan</li>
                    <li>Consultoría</li>
                    <li>Coaching</li>
                  </ul>
                </div>
                <div className="container-btns">
                  <a href="/pdf/01_Bio_SiMKT_HIGH.pdf" target="_blank">
                    <div className="btn">
                      <img src="/img/003BTN_Info_SiMA-8.png" alt=""/>
                      <div>
                        + Info
                      </div>
                    </div>
                  </a>
                  {/* <a href="https://www.facebook.com/pg/Turismo-Global-147885691941132/photos/?tab=album&album_id=3496553187074349" target="_blank">
                    <div className="btn">
                      <img src="/img/004BTN_Promo_SiMA-8.png" alt=""/>
                      <div>
                        Promociones
                      </div>
                    </div>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="second">
            {/* <img
              className="img-section"
              src="/img/imagen_003-100.jpg"
              alt="Yo si viajo"
              /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default iMarketing;