import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";

import './NavBar.scss';

import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

class NavBar extends Component {

  state = {
    right: false
  }

  isHome() {
    return (this.props.location.pathname === '/');
  }

  render() {
    const toggleDrawer = (side, open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({ ...this.state, [side]: open });
    };

    const open = (side) => event => {
      this.setState({
        ...this.state,
        [side]: !this.state.right
      });
    };

    return (
      <div className="container-navbar">
        <Fab
          className={`btn-icon-menu ${(this.state.right ? 'close': '')}`}
          aria-label="add"
          onClick={open('right')}
        >
          <img
            className="icon-show-menu"
            src="/img/002_menu_1.svg" alt=""/>
        </Fab>
        <SwipeableDrawer
          anchor="right"
          open={this.state.right}
          className="navBar-right"
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          <div
            className="container-links-navbar"
            role="presentation"
            onClick={toggleDrawer('right', false)}
            onKeyDown={toggleDrawer('right', false)}
          >
            <div className="container-close-menu"></div>
            <List>
              <div id="myMenu">
                <div data-menuanchor="nuestros-servicios">
                  <a href="/">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Inicio'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="#nuestros-servicios">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Nuestros servicios'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/#nuestros-beneficios">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Nuestros beneficios'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/#si-viajo">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Si viajo'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/#si-acepto">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Si acepto'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/#si-marketing">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Si marketing'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/#si-meetings">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Si meetings'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/#comentarios">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Comentarios'} />
                    </ListItem>
                  </a>
                </div>
                <div data-menuanchor="nuestros-servicios">
                  <a href="/blog">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Blog'} />
                    </ListItem>
                  </a>
                </div>
              </div>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default NavBar;