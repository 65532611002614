import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from './../../generals/home/header/header';
import OurServices from './../../generals/home/ourServices/ourServices';
import OurBenefists from './../../generals/home/ourBenefits/ourBenefists';
import Comments from './../../generals/home/comments/Comments';
import ITravel from './../../generals/home/iTravel/iTravel';
import IAccept from './../../generals/home/iAccept/iAccept';
import IMarketing from './../../generals/home/iMarketing/iMarketing';
import IMeetings from './../../generals/home/iMeetings/iMeetings';
import Footer from './../../generals/footer/footer';

import './Home.scss';

class Home extends Component {

  componentWillMount() {
    document.body.style.overflow = "hidden";
  }

  render() {
    return (
      <div>
        <ReactFullpage
          licenseKey = {'3BAC82AF-02CF4F33-82568041-67F694CF'}
          //Navigation
          navigation = {false}
          anchors = {['inicio', 'nuestros-servicios', 'nuestros-beneficios', 'si-viajo', 'si-acepto', 'si-marketing', 'si-meetings', 'comentarios', 'contacto']}
          menu = {'#myMenu'}
          touchSensitivity = {17}
          //Scrolling
          scrollingSpeed = {800}
          scrollOverflow = {true}
          scrollBar = {false}
          //Design
          verticalCentered = {true}
          controlArrows = {true}
          parallax = {false}
          parallaxOptions =  {{type: 'reveal', percentage: 62, property: 'translate'}}
          cards = {false}
          cardsOptions = {{perspective: 100, fadeContent: true, fadeBackground: true}}
          
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section">
                  <Header/>
                </div>
                <div className="section">
                  <OurServices/>
                </div>
                <div className="section">
                  <OurBenefists/>
                </div>
                <div className="section">
                  <ITravel />
                </div>
                <div className="section">
                  <IAccept />
                </div>
                <div className="section">
                  <IMarketing />
                </div>
                <div className="section">
                  <IMeetings />
                </div>
                <div className="section">
                  <Comments/>
                </div>
                <div className="section section-footer">
                  <Footer />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    );
  }
}

export default Home;